<template>
  <div>
    <v-bottom-sheet
        right
        app
        v-model="navigation"
        disable-route-watcher
        persistent
        bottom
        width="80%"
        v-if="selectButtonObject.show"
    >
      <list_wrapper
          :dialog="navigation"
          :list_name="selectButtonObject.name"
          :list_group_name="selectButtonObject.group"
          :hierarchy="selectButtonObject.hierarchy"
          :only_groups="selectButtonObject.only_groups"
          :selectable="true"
          :title="selectButtonObject.title"
          :element_title="selectButtonObject.element_title"
          :modal_wrapper="true"
          :action_props="selectButtonObject.action_props"
          :sync_action_props="selectButtonObject.sync_action_props"
          :action_filter="selectButtonObject.action_filter"
          :owner_id="(selectButtonObject.action_props || {}).owner_id || 0"
          :document="selectButtonObject.document"
          :full_screen="selectButtonObject.full_screen"
          :show-additional-field="selectButtonObject.showAdditionalField || false"
          @closeDialog="closeListDialog"
          @selectItem="selectedRow"
      />
    </v-bottom-sheet>
    <v-autocomplete
        v-model="model"
        color="grey lighten-1"
        :items="entries"
        :loading="isLoading"
        :search-input.sync="search"
        dense
        :filled="filled"
        :chips="chips"
        :small-chips="chips"
        :multiple="multiple"
        :disabled="disabled"
        no-data-text="Не знайдено жодного рахунка"
        hide-details
        :label="label"
        placeholder="почніть вводити текст для пошуку"
        @change="$emit('autocompleteChange', model)"
        :solo="solo"
        :style="style_"
        :menu-props="menuProps"
        clearable
        return-object
    >
      <template v-slot:selection="data" v-if="chips">
        <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="remove(data.item)"
            class="my-1"
            color="white"
        >
          <v-icon color="secondary" class="mr-3">
            mdi-bank-outline
          </v-icon>
          <span class="grey--text text--darken-3">{{ data.item.text }}</span>
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-icon>
            <v-icon color="success darken-1" size="20" class="mr-3">
              mdi-account-supervisor-outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle v-html="data.item.text"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
      <template v-slot:prepend-item>
        <div class="mb-5 prepend-list-item">
          <div class="prepend-list-item-buttons">
            <div class="a-list">
              <a href="#" @click="openList">Показати усі елементи у списку</a>
            </div>
            <div class="a-button">
              <v-btn @click="openModal" small class="grey darken-2" depressed>
                <v-icon color="white">mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import {getCityIcon} from "@/utils/icons";
import {mapGetters} from "vuex";
import {getActionFilter} from "@/utils/accounting";

export default {
  name: "ACC_Subconto",
  props: {
    owner_id: {
      type: Number,
      default: null
    },
    filled: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: null
    },
    except_value: {
      type: Number,
      default: null
    },
    select_type: {
      type: String,
      default: 'elements'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    style_: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'План рахунків'
    },
    selectButtonObject: {
      type: Object,
      default() {
        return {
          show: false,
          name: '',
          group: '',
          hierarchy: false,
          title: '',
          element_title: '',
          only_groups: false,
          action_props: {},
          action_filter: '',
          document: false,
          full_screen: false,
          sync_action_props: false,
          clearable: false,
          showAdditionalField: false
        }
      }
    },
    computedGetter: {
      type: String,
      default: ''
    },
    computedAction: {
      type: String,
      default: ''
    },
    importGetter: {
      type: Object,
      default() {
        return {}
      }
    },
    importModule: {
      type: String,
      default() {
        return 'icons';
      }
    },
    actionProps: {
      type: Object,
      default() {
        return {}
      }
    },
    actionFilter: {
      type: String,
      default: ''
    },
    syncActionProps: {
      type: Boolean,
      default: false
    },
    showAdditionalField: {
      type: Boolean,
      default: false
    }
  },
  components: {
    list_wrapper: () => import("@/components/accounting/list_modal/list_wrapper")
  },
  computed: {
    ...mapGetters(
        {
          reload_nomenclature: 'get_reload_nomenclature'
        }
    ),
    menuProps() {
      return {
        closeOnContentClick: this.modal
      }
    },
    actionPropsForFetch: {
      get: function () {
        return Object.assign({}, this.actionProps, this.selectButtonObject.action_props)
      }
    }
  },
  data: () => ({
    entries: [],
    isLoading: false,
    model: null,
    search: null,
    modal: false,
    selectedItem: null,
    navigation: false,
    watcher_filter: null,
    watcher_action_props: null,
    watcher_computed_action: null
  }),
  methods: {
    getActionFilter,
    closeListDialog() {
      this.navigation = false
    },
    chooseByRow() {
      this.fetch(true)
    },
    selectedRow(payload) {
      if (payload === undefined) {
        this.selectedItem = null
        this.model = Object.assign({}, {})
      } else {
        this.selectedItem = Object.assign({}, payload)
        this.model = Object.assign({}, {text: payload.short_name, value: payload.id})
      }
      this.$emit('autocompleteChange', this.model)
    },
    openList() {
      this.navigation = true
    },
    openModal() {
      this.modal = true
    },
    closeModal() {
      this.modal = false
      this.fetch()
    },
    fetch() {
      if (this.computedGetter) {
        let entries = []
        let comp_data = this.$store.getters[this.computedGetter]

        const find_position = this.computedGetter.indexOf('_list_select') || 0
        const loading_getter = (this.computedGetter || '').substring(0, find_position) + '_loaded'
        let data_loaded = loading_getter === '_loaded' ? false : this.$store.getters[loading_getter]
        data_loaded = this.syncActionProps ? false : data_loaded

        const action_filter = this.getActionFilter(this.actionFilter)

        if (!data_loaded || !comp_data.length) {
          const add_action_props = this.owner_id ? {owner_id: this.owner_id} : {}
          this.$store.commit("SET_ACCOUNTING_DATA_LOADING", {state: loading_getter, value: true})
          this.$store.dispatch(this.computedAction, Object.assign({},
              this.actionProps,
              this.selectButtonObject.actionProps, add_action_props))
              .then(() => {
                entries = this.$store.getters[this.computedGetter].map(i => Object.assign({}, i))
                if (action_filter) {
                  entries = (entries || []).filter(action_filter)
                }
                this.entries = entries

                if (this.value) {
                  this.model = this.entries.find(item => item.value === this.value)
                } else {
                  this.model = this.multiple ? [] : null
                }
              })
        } else {
          entries = this.$store.getters[this.computedGetter].map(i => Object.assign({}, i))
          if (action_filter) {
            entries = (entries || []).filter(action_filter)
          }
          this.entries = entries

          if (this.value) {
            this.model = this.entries.find(item => item.value === this.value)
          } else {
            this.model = this.multiple ? [] : null
          }
        }
      } else {
        if (this.importModule === 'icons') {
          import('/src/utils/icons')
              .then(module => {
                this.entries = module[this.importGetter.name]
              })
        } else {
          import('/src/utils/accounting').then(module => {
            this.entries = module[this.importGetter.name]
            if (this.value) {
              this.model = this.entries.find(item => item.value === this.value)
            } else {
              this.model = this.multiple ? [] : null
            }
          })
        }
      }
    },
    remove(item) {
      const fElement = this.model.find(i => i.value === item.value)
      const index = this.model.indexOf(fElement)
      if (index >= 0) this.model.splice(index, 1)
    },
    getCityIcon,
    watch_filter() {
      this.watcher_filter = this.$watch(
          'actionFilter',
          {
            immediate: false,
            deep: false,
            handler(payload) {
              let entries = []
              const action_filter = this.getActionFilter(payload)

              entries = this.$store.getters[this.computedGetter]

              if (action_filter) {
                entries = (entries || []).filter(action_filter)
              }

              this.entries = entries

              if (payload) {
                this.model = this.entries.find(item => item.value === payload)
              } else {
                this.model = this.multiple ? [] : null
              }
            }
          }
      )
    },
    watch_action_props() {
      this.watcher_action_props = this.$watch(
          'actionPropsForFetch',
          {
            immediate: false,
            deep: false,
            handler(payload, payload_old) {
              if (!payload && !payload_old) return

              const payload_n = payload || {}
              const payload_o = payload_old || {}

              if (JSON.stringify(payload_n) === JSON.stringify(payload_o)) return;

              if (this.computedGetter) {
                let entries = []

                const find_position = this.computedGetter.indexOf('_list_select') || 0
                const loading_getter = (this.computedGetter || '').substring(0, find_position) + '_loaded'
                const loading_state = loading_getter.replace('_loaded', '_loading').replace('get_', '')

                const action_filter = this.getActionFilter(this.actionFilter)

                this.$store.commit("SET_ACCOUNTING_DATA_LOADING", {state: loading_state, value: true})
                this.$store.dispatch(this.computedAction, payload)
                    .then(() => {
                      entries = this.$store.getters[this.computedGetter].map(i => Object.assign({}, i))
                      if (action_filter) {
                        entries = (entries || []).filter(action_filter)
                      }
                      this.entries = entries

                      const inputValue = this.entries.find(item => item[this.itemValue] === this.value)
                      if (!inputValue) {
                        this.inputValue = null

                      } else {
                        this.inputValue = inputValue[this.itemText]
                      }
                    })
              }
            }
          }
      )
    },
    watch_computed_action() {
      this.watcher_computed_action = this.$watch(
          'computedAction',
          {
            immediate: false,
            deep: false,
            handler(new_value, old_value) {
              if (new_value !== old_value) {
                this.onComputedActionChange(new_value)
              }
            }
          }
      )
    },
    onComputedActionChange(computedAction) {
      if (this.computedGetter) {
        let entries = []
        let comp_data = this.$store.getters[this.computedGetter]

        const find_position = this.computedGetter.indexOf('_list_select') || 0
        const loading_getter = (this.computedGetter || '').substring(0, find_position) + '_loaded'
        const loading_state = loading_getter.replace('_loaded', '_loading').replace('get_', '')
        let data_loaded = loading_getter === '_loaded' ? false : this.$store.getters[loading_getter]
        data_loaded = this.syncActionProps ? false : data_loaded

        const action_filter = this.getActionFilter(this.actionFilter)

        if (!data_loaded || !comp_data.length) {
          const add_action_props = this.owner_id ? {owner_id: this.owner_id} : {}
          this.$store.commit("SET_ACCOUNTING_DATA_LOADING", {state: loading_state, value: true})
          this.$store.dispatch(computedAction, Object.assign({}, this.actionPropsForFetch, add_action_props))
              .then(() => {
                entries = this.$store.getters[this.computedGetter].map(i => Object.assign({}, i))
                if (action_filter) {
                  entries = (entries || []).filter(action_filter)
                }
                this.entries = entries

                const inputValue = this.entries.find(item => item[this.itemValue] === this.value)
                if (inputValue) {
                  this.inputValue = inputValue[this.itemText]
                }
                this.inputValue = this.value
              })
        } else {
          entries = this.$store.getters[this.computedGetter].map(i => Object.assign({}, i))
          if (action_filter) {
            entries = (entries || []).filter(action_filter)
          }
          this.entries = entries

          const inputValue = this.entries.find(item => item[this.itemValue] === this.value)
          if (inputValue) {
            this.inputValue = inputValue[this.itemText]
          }
          this.inputValue = this.value
          this.$emit('inputValue', {field: this.field, text: this.inputValue, name: 'watch_value'})
        }
      }
    }
  },
  watch: {
    search() {
      if (this.entries.length > 0) return

      if (this.isLoading) return

      this.isLoading = true

      this.fetch()
    },
    computedAction() {
      this.fetch()
    },
    value: {
      handler(payload) {
        if (payload) {
          this.model = this.entries.find(item => item.value === payload)
        } else {
          this.model = this.multiple ? [] : null
        }
      },
    },
    reload_nomenclature: {
      handler(payload) {
        if (payload) {
          this.fetch()
          this.$store.commit("RELOAD_NOMENCLATURE_SELECT", false)
        }
      }
    }
  },
  created() {
    this.watch_computed_action()
    if (this.syncActionProps) {
      this.watch_action_props()
    }
    this.fetch()
  },
  beforeDestroy() {
    if (this.watcher_filter) {
      this.watcher_filter()
    }
    if (this.watcher_action_props) {
      this.watcher_action_props()
    }
    if (this.watcher_computed_action) {
      this.watcher_computed_action()
    }
  }
}
</script>

<style scoped lang="scss">
.main-height:deep(.v-input__control) {
  min-height: 34px !important;
}

.main-height:deep(.v-input__control .v-input__slot) {
  min-height: 34px !important;
}

.close {
  position: absolute;
  right: -8px;
  top: -9px;
  z-index: 1;
}
</style>